import React from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ProductBlockAside from './ProductBlockAside';
import './style/CardProduct.css';

const CardProduct = () => {

return (
  <div className="cardp-card-product">
    <div className="cardp-breadcrumb-container">
      <div className="cardp-breadcrumbs">
        <a href="/">Главная</a> &gt; <span>Каталог</span> &gt; <span>Молоко, сыр, яйца</span> &gt; <span>Название товара</span>
      </div>
        <h1 className="cardp-product-title">Название товара</h1>
    </div>
    <div className="cardp-product-header">
      <div className="cardp-product-art">арт. 371431</div>
      <button className="cardp-favorite-button"><FavoriteBorderIcon /> в избранное</button>
    </div>
    <div className="cardp-product-main">
      <div className="cardp-product-gallery">
        <div className="cardp-thumbnails">
          {/* Вставьте здесь реальные изображения или плейсхолдеры */}
            <div className="cardp-thumbnail"></div>
            <div className="cardp-thumbnail"></div>
            <div className="cardp-thumbnail"></div>
            <div className="cardp-thumbnail"></div>
            <div className="cardp-thumbnail"></div>
          {/* ... */}
        </div>
        <div className="cardp-main-image">
          {/* Вставьте здесь реальное большое изображение или плейсхолдер */}
        </div>
      </div>
      <div className="cardp-card-product-details">
            {/* Блок цен и характеристик */}
        <div className="cardp-product-pricing">
          <div className="cardp-tovar-name">
            <div className="cardp-original-price">Цена товара</div>
            <div className="cardp-current-price">Цена товара</div>
          </div>
          <button className="cardp-add-to-cart-btn">В корзину</button>
        </div>
        <div className="cardp-product-properties">
            {/* Блок характеристик */}
          <div className="cardp-brand-row">
            <span className="cardp-property-name">Бренд</span>
            <span className="cardp-property-value">ПРОСТОКВАШИНО</span>
          </div>
          <div className="cardp-country-row">
            <span className="cardp-property-name">Странапроизводителя</span>
            <span className="cardp-property-value">Казахстан</span>
          </div>
          <div className="cardp-package-row">
            <span className="cardp-property-name">Упаковка</span>
            <span className="cardp-property-value">200 г</span>
          </div>
        </div>
      </div>
      <div className='cardp-aside'>
          <ProductBlockAside/>
          <ProductBlockAside/>
          <ProductBlockAside/>
          <ProductBlockAside/>
      </div>
    </div>
  </div>
);
};

export default CardProduct;
