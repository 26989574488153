import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';

const CreateUserPage = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Отправка данных формы, например, через API
    console.log(user);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Создать пользователя
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Логин"
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Пароль"
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Номер телефона"
          onChange={handleChange}
          margin="normal"
        />

        {/* Добавьте здесь другие поля, если необходимо */}
        <Button variant="contained" color="primary" type="submit">
          Создать
        </Button>
      </form>
    </Container>
  );
};

export default CreateUserPage;
