import React, { useState } from 'react';
import './DriversComponent.css'; // Здесь будут стили вашего компонента

const DriversComponent = () => {
  const [drivers, setDrivers] = useState([
    { id: 1, name: 'Иванов И.П.', documents: 'link-to-documents-1' },
    { id: 2, name: 'Козлов А.В.', documents: 'link-to-documents-2' },
    { id: 3, name: 'Сергеев В.А.', documents: 'link-to-documents-3' },
    // ... другие водители
  ]);

  const handleAdd = () => {
    // Логика добавления нового водителя
  };

  const handleDelete = (driverId) => {
    // Логика удаления водителя
    setDrivers(drivers.filter(driver => driver.id !== driverId));
  };

  return (
    <div className="drivers-component">
      <div className="header">
        <h2>Водители</h2>
        <div className="button-panel">
          <button onClick={handleAdd}>Добавить</button>
          {/* Кнопка удаления будет в каждом элементе списка водителей */}
        </div>
      </div>
      <div className="drivers-list">
        {drivers.map(driver => (
          <div key={driver.id} className="driver-item">
            <span>{driver.name}</span>
            <button onClick={() => handleDelete(driver.id)}>Удалить</button>
            <a href={driver.documents} target="_blank" rel="noopener noreferrer">
              <img src="path-to-document-icon" alt="Документы" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DriversComponent;
