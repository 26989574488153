import React from 'react';
import { Card, CardContent, Typography, CardHeader, Avatar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import BusinessIcon from '@mui/icons-material/Business'; // Иконка для организации
import WcIcon from '@mui/icons-material/Wc'; // Иконка для пола
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Иконка для подтверждения
import HighlightOffIcon from '@mui/icons-material/HighlightOff'; // Иконка для отрицания

const UserProfile = ({ user }) => {
  const initial = user && user.firstName ? user.firstName.charAt(0) : '?';

  return (
    <Card sx={{ maxWidth: "100%", height: "100%", margin: 'auto' }}>
      <CardHeader
        avatar={
          <Avatar src={user?.avatarUrl} aria-label="user-avatar">
            {initial}
          </Avatar>
        }
        title={`${user?.firstName || 'Имя'} ${user?.lastName || 'Фамилия'}`}
        subheader={user?.email || 'email@example.com'}
      />
      <CardContent>
        <List>
          <ListItem>
            <ListItemIcon>
              <PermIdentityIcon />
            </ListItemIcon>
            <ListItemText primary="Роль в системе" secondary={user?.role || 'Не указана'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email" secondary={user?.email || 'Не указан'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Адрес" secondary={user?.address || 'Не указан'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Организация" secondary={user?.organization || 'Не указана'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <WcIcon />
            </ListItemIcon>
            <ListItemText primary="Пол" secondary={user?.gender || 'Не указан'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {user?.isClient ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Клиент" secondary={user?.isClient ? 'Да' : 'Нет'} />
          </ListItem>
        </List>
        <Typography variant="body2" color="text.secondary" mt={2}>
          Дополнительная информация о пользователе может быть здесь.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserProfile;
