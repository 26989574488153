// src/components/ProductList.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './style/ProductList.css'; // Стили для списка товаров
import './style/ProductItem.css'; // Стили для элемента товара

function ProductList() {
  const [product, setOrders] = useState([]);

  useEffect(() => {
    axios.get("http://213.157.41.2:7388/api/v1/products", {
      headers: {
        'x-api-key':'12345xxa'
      }
    })
    .then((res) => {
      setOrders(res.data);
    })
    .catch((error) => {
      console.error("Ошибка при получении данных о заказах:", error);
    });
  }, []);

  return (
<div className="product-list">
  {product.slice(0,8).map((item, index) => ( 
    <div className="product-item">
      <Link to="/CardProduct" className="product-item-link">
      <div className="product-image">{item.product_photo}</div>
      <h3>Название товара: {item.name}</h3>
      <p className="price">Цена: {item.price}</p>
      </Link>
      <button onClick={() => console.log('Добавлено в корзину')}>В корзину</button>
    </div>
  ))}

</div>
  );
}

export default ProductList;
