import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./ClientsList.css";

const ClientsList = () => {
  const [clients, setCLients] = useState([]);
  useEffect(() => {
    axios
      .get("http://213.157.41.2:7388/api/v1/clients", {
        headers: {
          "x-api-key": "12345xxa",
        },
      })
      .then((res) => {
        setCLients(res.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных о заказах:", error);
      });
  }, []);
  const navigate = useNavigate();

  // Метод для обработки добавления нового клиента
  const handleAddClient = () => {
    navigate("/ClientsList/AddClientModal"); // Путь должен совпадать с маршрутом в вашем роутере
  };

  // Функция для перехода к странице деталей заказа
  const handleRowClick = (clientId) => {
    navigate(`/ClientsList/ClientDetails/${clientId}`);
  };

  // Здесь могут быть методы для обработки поиска клиента и других действий

  return (
    <div className="clients-list-container">
      <div className="clients-list-header">
        <div className="search-bar">
          <input type="text" placeholder="Поиск по наименованию" />
          <input type="text" placeholder="БИН" />
          <input type="text" placeholder="№ договора" />
        </div>
        <button className="add-client-btn" onClick={handleAddClient}>
          Добавить клиента
        </button>
      </div>
      <table className="clients-table">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>БИН</th>
            <th>Телефон</th>
            <th>Адрес</th>
            <th>E-Mail</th>
          </tr>
        </thead>
        <tbody>
          {clients.slice(0,10).map((client, index) => (
            <tr key={client.id} onClick={() => handleRowClick(client.id)}>
              <td>{client.name}</td>
              <td>{client.bin}</td>
              <td>{client.phone}</td>
              <td>{client.address}</td>
              <td>{client.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientsList;
