import React from 'react';
import './style/OrderDetailsPage.css';

const OrderDetailsPage = () => {
  // Здесь должна быть логика для получения данных о заказе

  return (
    <div className="order-details-page">
      <div className="breadcrumbs">
        <span><a href="/">Главная</a></span> &gt; <span><a href="/OrdersPage">Заказы</a></span> &gt; <span>Просмотр заказа</span>
      </div>
      <div className="order-details-content">
        <h1>Детали заказа</h1>
        <div className="product-list1">
          {/* Здесь должен быть список товаров в заказе */}
          <div className="product-card">
            <div className="product-image1">
              {/* Здесь должна быть фотография товара */}
              <img src="path/to/product/image.jpg" alt="Товар" />
            </div>
            <div className="product-info">
              <div className="product-header">
                <div className="product-name-label">Название товара</div>
                <div className="product-quantity-label">Количество</div>
                <div className="product-price-label">Цена</div>
              </div>
              <div className="product-details">
                <div className="product-name">Название товара</div>
                <div className="product-quantity">2</div>
                <div className="product-price">500,00 Т</div>
              </div>
            </div>
            
          </div>
          <div className="product-card">
            <div className="product-image1">
              {/* Здесь должна быть фотография товара */}
              <img src="path/to/product/image.jpg" alt="Товар" />
            </div>
            <div className="product-info">
              <div className="product-header">
                <div className="product-name-label">Название товара</div>
                <div className="product-quantity-label">Количество</div>
                <div className="product-price-label">Цена</div>
              </div>
              <div className="product-details">
                <div className="product-name">Название товара</div>
                <div className="product-quantity">1</div>
                <div className="product-price">500,00 Т</div>
              </div>
            </div>
            
          </div>
          {/* Повторите для каждого товара в заказе */}
        </div>
        <div className="total-price">
          Итого: 1 500,00 Т
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsPage;
