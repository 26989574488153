import React, { useState } from "react";
import "./style/DeliveryForm.css"; // Убедитесь, что это правильный путь к вашему CSS файлу

const DeliveryForm = () => {
    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const handleTimeSlotSelection = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
    };

    const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
};

return (
    <div className="delivery-container">
        <div className="breadcrumbs">
            <span>
                <a href="/">Главная</a>
            </span>
            &gt;
            <span>
                <a href="/Cart">Корзина</a>
            </span>
                <h1>Доставка</h1>
        </div>

        <div className="delivery-form">
            <div className="deliveryF-block-2">
                <div className="address-block">
                        <h2>Куда</h2>
                        <div className="adres-block-1">
                    <div className="address-field">
                        <label>НаселенныйПункт</label>
                        <select className="Home1234" name="city">
                            <option value="astana" className="Home1234">Астана</option>
                                {/* Добавьте другие опции городов, если необходимо */}
                        </select>
                    </div>
                    <div className="address-field">
                        <label>Улица</label>
                        <input type="text" name="street" className="Home1234"/>
                    </div>
                    <div className="address-field">
                        <label>Дом</label>
                        <input type="text" name="house" className="Home123"/>
                    </div>
                    <div className="address-field">
                        <label>Квартира</label>
                        <input type="text" name="apartment" className="Home123"/>
                    </div>
                    <div className="address-field">
                        <label>Дополнительно</label>
                        <input type="text" name="additional" className="Home1234"/>
                    </div>
                    </div>
                </div>

                <div className="time-block">
                <h2>Когда</h2>
                <div className="time-block-1">
                <label>
                <div className="del-date">Дата</div>
                <input type="date" name="date" />
                </label>
                <div className="time-slots">
                <div className="time-slots-123">
                <div>Время</div>
                <div className="del-time"></div>
                {[
                "8:00 - 14:00",
                "14:00 - 18:00",
                "18:00 - 20:00",
                "20:00 - 22:00",
                ].map((timeSlot) => (
                <button
                key={timeSlot}
                type="button"
                className={`time-slot ${
                selectedTimeSlot === timeSlot ? "active" : ""
                }`}
                onClick={() => handleTimeSlotSelection(timeSlot)}
                >
                {timeSlot}
                </button>
                ))}
                </div>


                </div>
                </div>

                </div>

                <div className="login-block">
                <h2>Вход</h2>

                <div className="login-block-1">
                <input
                type="tel"
                name="phone"
                placeholder="Введите номер телефона"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className="home12345"
                />
                <button type="button" className="login-button">
                Войти
                </button>
                </div>
                </div>
            </div>
            <div className="deliveryF-block-3">
                <div className="summary-block">
                    <div className="oplata-1">3 товара 10590 T</div>
                    <div className="oplata-1">Скидка -0.01 T</div>
                    <div className="oplata-1">Итог 1051,09 T</div>
                </div>
                <div className="payment-buttons">
                    <button type="submit" className="pay-site">
                        Оплатить на сайте
                    </button>
                    <button type="button" className="pay-delivery">
                        Оплатить при получении
                    </button>
                </div>
            </div>
        </div>
    </div>
);
};

export default DeliveryForm;