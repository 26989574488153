import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const [isDirectoriesExpanded, setIsDirectoriesExpanded] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { name: 'Заказы', path: '/orders' },
    { name: 'Клиенты', path: '/ClientsList' },
    { name: 'Чат', path: '/chat' },
    { name: 'Заявки', path: '/OrdersComponent' },
    { name: 'Справочники', },
    
    // ... другие пункты меню с их путями
  ];

  const directoriesSubMenu = [
    { name: 'Водители', path: '/driversComponent' },
    { name: 'Машины', path: '/vehicles' },
    { name: 'Организации', path: '/OrganizationProfile' },
    { name: 'Договора', path: '/ContractsList' },
    { name: 'Пользователи', path: '/Users' },
    { name: 'Виды документов', path: '/DocumentTypes' },
    { name: 'Товары', path: '/ProductComponent' },
    { name: 'Районы доставки', path: '/DeliveryAreasComponent' },
    // ... другие пункты подменю справочников с их путями
  ];

  const handleMenuItemClick = (path) => {
    if (path) {
      navigate(path);
    } else {
      setIsDirectoriesExpanded(!isDirectoriesExpanded);
    }
  };

  return (
    <aside className="sidebar">
      <nav className="sidebar__menu">
        <ul className="menu__list">
          {menuItems.map((item) => (
            <li
              key={item.name}
              className={`list__item ${item.name === 'Справочники' && isDirectoriesExpanded ? 'list__item--expanded' : ''}`}
              onClick={() => handleMenuItemClick(item.path)}
            >
              {item.name}
              {item.name === 'Справочники' && (
                <ul className={`submenu ${isDirectoriesExpanded ? 'submenu--visible' : ''}`}>
                  {directoriesSubMenu.map(subItem => (
                    <li key={subItem.name} className="submenu__item" onClick={() => handleMenuItemClick(subItem.path)}>
                      {subItem.name}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
