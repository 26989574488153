import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'
// Site
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import ProfilePage from './pages/ProfilePage';
import Header from './components/Header';
import Footer from './components/Footer';
import Shop from './components/store/Main';
import ArticlePage from './pages/ArticlePage';
// Store
import Cart from './components/store/Cart'
import OrdersPage from './components/store/OrdersPage';
import Favourites from './components/store/Favourites';
import CardProduct from './components/store/CardProduct';
import SearchingResults from './components/store/SearchingResults';
import ResultsCatalog from './components/store/ResultsCatalog';
import DeliveryForm from './components/store/DeliveryForm';
import OrderDetailsPage from './components/store/OrderDetailsPage';
// CRM
import Crm from './components/crm/Header&SIdebar/workspace';
import Orders from './components/crm/Header&SIdebar/orders';
import OrderPage from './components/crm/Header&SIdebar/orderpage';
import DriversComponent from './components/crm/Reference.books/DriversComponent';
import Invoice from './components/crm/Header&SIdebar/invoice';
import ClientsList from './components/crm/Header&SIdebar/ClientsList';
import AddClientModal from './components/crm/Header&SIdebar/AddClientModal';
import Vehicles from './components/crm/Reference.books/Vehicles';
import ContractsList from './components/crm/Reference.books/ContractsList';
import AddContract from './components/crm/Reference.books/AddContract';
import Chat from './components/crm/Header&SIdebar/Chat';
import DeliveryAreasComponent from './components/crm/Reference.books/DeliveryAreasComponent';
import ClientDetails from './components/crm/Header&SIdebar/ClientDetails';
//Админ панель
import AdminPanel from './components/admin/HomePage';
import CreateUserPage from './components/admin/CreateUserPage'
import CreateProductPage from './components/admin/CreateProductPage'
import UserList from './components/admin/UserList';
import UserProfile from './components/admin/UserProfile';
import ProductList from './components/admin/ProductList';
import ProductProfile from './components/admin/ProductProfile';

function App() {
  return (
    <Router>
      <div className="app-container">
        <div className="header-container">
          <Header />
        </div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/articles/:articleId" element={<ArticlePage />} />

            <Route path="Orderspage" element={<OrdersPage />} />
            <Route path="Favourites" element={<Favourites />} />
            <Route path="Cart" element={<Cart />} />
            <Route path="CardProduct" element={<CardProduct />} />
            <Route path="SearchingResults" element={<SearchingResults />} />
            <Route path="ResultsCatalog" element={<ResultsCatalog />} />
            <Route path="DeliveryForm" element={<DeliveryForm />} />
            <Route path="OrderDetailsPage" element={<OrderDetailsPage />} />

            <Route path="Crm" element={<Crm />} />
            <Route path="/orders" element={<Orders />} />           
            <Route path="/orders/:id" element={<OrderPage />} />                       
            <Route path="/orders/:id/Invoice" element={<Invoice />} />
            <Route path="/ClientsList" element={<ClientsList />} />
            <Route path="/ClientsList/ClientDetails/:id" element={<ClientDetails/>} />
            <Route path="/ClientsList/AddClientModal" element={<AddClientModal />} />
            <Route path="/ContractsList" element={<ContractsList/>} />
            <Route path="/ContractsList/AddContract" element={<AddContract/>} />
            <Route path="/DriversComponent" element={<DriversComponent/>}></Route>
            <Route path="/Vehicles" element={<Vehicles/>} />
            <Route path="/DeliveryAreasComponent" element={<DeliveryAreasComponent/>} />            
            <Route path="/Chat" element={<Chat/>} />

            <Route path="/AdminPanel" element={<AdminPanel />} />
            <Route path="CreateUserPage" element={<CreateUserPage />} />
            <Route path="CreateProductPage" element={<CreateProductPage />} />
            <Route path="/users" element={<UserList users={[]} />} /> 
            <Route path="/userprofile/:id" element={<UserProfile user={{}} />} /> 
            <Route path="/products" element={<ProductList products={[]} />} /> 
            <Route path="/productprofile/:id" element={<ProductProfile product={{}} />} />
        </Routes>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </Router>
  );
}

export default App;
