import React, { useState } from 'react';
import FavouritesProductItem from './FavouritesProductItem';
import Pagination from './Pagination';
import CloseIcon from '@mui/icons-material/Close';
import './style/Favourites.css';

const Favourites = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsCount = 42; // Adjust based on your data
  const itemsPerPage = 6; // Adjust as needed

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="favourites-wrapper">
      <div className="breadcrumbs">
        <a href="/">Главная</a> &gt; <span>Избранное</span>
      </div>
      <h2 className="favourites-title">Избранное</h2>
      <div className="favourites-content">
        <aside className="favourites-filters">
        <div className="filter-header">ФИЛЬТР</div>
        <div className="price-filter">
          <div className="price-filter-header">
            <span className="filter-label">Цена</span>
            <button className="clear-filter">Очистить</button>
          </div>
          <div className="price-inputs">
            <input type="number" className="price-input" placeholder="от" />
            <input type="number" className="price-input" placeholder="до" />
          </div>
          <input type="range" className="price-range" min="1" max="100" />
        </div>
        <div className="categories-filter">
          <label className="category-option"><input type="checkbox" /> Молоко</label>
          <label className="category-option"><input type="checkbox" /> Сырьё</label>
          <label className="category-option"><input type="checkbox" /> Яйцо</label>
        </div>
        <div className="availability-filter">
          <label className="availability-switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
          <span className="availability-label">В наличии</span>
        </div>
        <button className="apply-filters">Применить</button>
        </aside>
        <div className="favourites-right">
        <div className="price-info-block">
        <button className="clear-filters">Очистить <CloseIcon /></button>
      </div>
          <div className="favourites-items">
            <FavouritesProductItem />
            <FavouritesProductItem />
            <FavouritesProductItem />
            <FavouritesProductItem />
            <FavouritesProductItem />
            <FavouritesProductItem />
            {/* More FavouritesProductItems can be added here */}
          </div>
          <button className="show-more">Показать еще</button>
          <Pagination
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Favourites;
