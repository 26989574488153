import React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'firstName', headerName: 'Имя', width: 150 },
  { field: 'lastName', headerName: 'Фамилия', width: 150 },
  // Добавьте другие поля по необходимости
];

const sampleUsers = [
  { id: 1, firstName: 'Иван', lastName: 'Иванов' },
  { id: 2, firstName: 'Петр', lastName: 'Петров' },
  { id: 3, firstName: 'Сидор', lastName: 'Сидоров' },
  // Добавьте другие примеры пользователей
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      <Button
        component={Link}
        to="/createuserpage"
        variant="contained"
        color="primary"
        style={{ marginLeft: '16px' }}
      >
        Добавить нового пользователя
      </Button>
    </GridToolbarContainer>
  );
}

const UserList = () => {
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    navigate(`/userprofile/${params.id}`); // Предполагается, что у вас есть маршрут /userprofile/:id для отображения профиля пользователя
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={sampleUsers}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default UserList;
