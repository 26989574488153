import React, { useState } from 'react';
import './AddContract.css';

const AddContract = () => {
  const [contract, setContract] = useState({
    number: '',
    date: '',
    products: [],
    contacts: [],
    bank: '',
    bankAccount: ''
  });

  // Обработчики для изменения состояния
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContract({ ...contract, [name]: value });
  };

  const handleSave = () => {
    // Здесь логика для сохранения договора
    console.log(contract);
  };

  // Добавить, удалить и установить контактным - функции, которые необходимо реализовать

  return (
    <div className="add-contract-container">
      <h1>Добавление договора</h1>
      <div className="contract-details">
        <input 
          type="text" 
          placeholder="Номер" 
          name="number" 
          value={contract.number} 
          onChange={handleChange} 
        />
        <input 
          type="date" 
          name="date" 
          value={contract.date} 
          onChange={handleChange} 
        />
      </div>
      {/* Таблица товаров */}
      {/* ... */}
      {/* Контактные данные */}
      {/* ... */}
      {/* Расчетный счет и банк */}
      <div className="bank-details">
        <input 
          type="text" 
          placeholder="Банк" 
          name="bank" 
          value={contract.bank} 
          onChange={handleChange} 
        />
        <input 
          type="text" 
          placeholder="Расчетный счет" 
          name="bankAccount" 
          value={contract.bankAccount} 
          onChange={handleChange} 
        />
      </div>
      <div className="contract-actions">
        <button onClick={handleSave}>Сохранить</button>
        <button>Отмена</button>
      </div>
    </div>
  );
};

export default AddContract;
