import React from 'react';

const ContactPage = () => {
  return (
    <div>
      <h2>Contact Us</h2>
      <p>Email: contact@example.com</p>
      <p>Phone: +1234567890</p>
    </div>
  );
};

export default ContactPage;
