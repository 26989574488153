import React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'name', headerName: 'Название', width: 150 },
  { field: 'price', headerName: 'Цена', type: 'number', width: 110 },
  // Добавьте другие поля по необходимости
];

const sampleProducts = [
  { id: 1, name: 'Товар 1', price: 100 },
  { id: 2, name: 'Товар 2', price: 200 },
  { id: 3, name: 'Товар 3', price: 300 },
  // Добавьте другие примеры товаров
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      <Button
        component={Link}
        to="/createproductpage" // Убедитесь, что этот путь соответствует вашему роутингу
        variant="contained"
        color="primary"
        style={{ marginLeft: '16px' }}
      >
        Добавить новый товар
      </Button>
    </GridToolbarContainer>
  );
}

const ProductList = () => {
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    navigate(`/productprofile/${params.id}`); // Предполагается, что у вас есть маршрут /productprofile/:id для отображения профиля товара
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={sampleProducts}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        components={{
          Toolbar: CustomToolbar,
        }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default ProductList;
