import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './orderpage.css';
import axios from 'axios';

const OrderPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrders] = useState([]);
  const [item, setItems] = useState([]);

  const handleCancel = () => {
    // Обработка нажатия на "Отмена"
  };

  const handleSave = () => {
    // Обработка нажатия на "Сохранить"
  };

  const handleAddContract = () => {
    navigate('/orders/' + id + '/Invoice');
  };

  // Выгрузка заказа
  useEffect(() => {
    axios
      .get('http://213.157.41.2:7388/api/v1/orders/' + id, {
        headers: {
          'x-api-key': '12345xxa',
        },
      })
      .then((res) => {
        setOrders(res.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении данных о заказах:', error);
      });
  }, []);

  // Выгрузка элементов заказа (таблица с деталями)
  useEffect(() => {
    axios
      .get('http://213.157.41.2:7388/api/v1/orderItems/' + id, {
        headers: {
          'x-api-key': '12345xxa',
        },
      })
      .then((res) => {
        setItems(res.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении данных о заказах:', error);
      });
  }, []);

  return (
    <div>
    
    {order.map((orders) => (
      <div className="order-page-container">
        <div className="order-header">
          <h1>Заказ №{orders.id || ''}</h1>
            <div>
              <span className="order-status">Статус: {orders.id_status || ''}</span>
              <button className="create-invoice-btn" onClick={handleAddContract}>
                Создать накладную
              </button>
            </div>
        </div>  
        <div className="order-details">
          <p>Клиент: {orders.client || ''}</p>
          <p>Договор: {orders.contract || ''}</p>
          <p>Адрес: {orders.address || ''}</p>
          <div className="delivery-info">
            <p>Дата доставки: {orders.deliveryDate || ''}</p>
            <p>Машина: {orders.vehicle || ''}</p>
            <p>Водитель: {orders.driver || ''}</p>
          </div>
        </div>
        <div>
        <table className="order-items">
          <thead>
            <tr>
              <th>Наименование</th>
              <th>Кол-во</th>
              <th>Ед. изм</th>
              <th>Цена</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
        
          <tr key={item.name}>
            <td>{item.name}</td>
            <td>{item.quantity}</td>
            <td>{item.unit}</td>
            <td>{item.price}</td>
            <td>{item.sum}</td>
            </tr>
            
          </tbody>
        </table>
        </div>
        <div className="order-total">
          <p>Итого: {orders.total || ''}</p>
        </div>
        <div className="order-actions">
          <button onClick={handleCancel}>Отмена</button>
          <button onClick={handleSave}>Сохранить</button>
        </div>
      </div>
    ))}
    </div>
  );
};

export default OrderPage;
