import React from 'react';
import Article from './Article';
import './style/Articles.css'; 

function Articles() {
  // Здесь может быть массив статей, полученный из API или другого источника
  const articles = [
  // Заглушка для примера
  { id: 1, date: '01.01.2012', title: 'Заголовок статьи', summary: 'Подробная информация...' },
  { id: 2, date: '01.01.2012', title: 'Заголовок статьи', summary: 'Подробная информация...' },
  { id: 3, date: '01.01.2012', title: 'Заголовок статьи', summary: 'Подробная информация...' },
  // ...и так далее для других статей
  ];

return (
  <div className="articles-container">
    {articles.map((article) => (
    <Article key={article.id} date={article.date} title={article.title} summary={article.summary} />
    ))}
  </div>
);
}

export default Articles;
