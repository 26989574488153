// src/components/Main.js

import React, { useState } from 'react';
import TopBanner from './TopBanner';
import ProductList from './ProductList';
import SpecialOffers from './SpecialOffers';
import Map from './Map';
import Articles from './Articles';
import OrdersPage from './OrdersPage';
import './style/Main.css';

function Main() {
  const [isOrdersPageVisible, setOrdersPageVisible] = useState(false);

  const handleOrdersClick = () => {
    setOrdersPageVisible(true);
  };

  return (
    <main className="main-content">
      <TopBanner />
      <ProductList />
      <SpecialOffers />
      <Map />
      <Articles />
      {isOrdersPageVisible && <OrdersPage />}
    </main>
  );
}

export default Main;
