import React from 'react';
import ProductItem from './ProductItem';
import './style/SearchingResults.css';

function SearchingResults() {
  return (
    <div className="SearchingResults-conteiner">
        <div className="SearchingResults-header">
            <div>
                <h1 className="SRZagalovok">
                    Результат поиска
                </h1>
            </div>
            <div>
                <p className="SRtext">
                    по запросу <span className="SREda">Еда</span>
                </p>
            </div>
        </div>
        <div className="SearchingResults-content">
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
            <ProductItem />
        </div>
    </div>
    
  );
}

export default SearchingResults;
