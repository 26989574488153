import React, { useState } from 'react';
import './Workspace.css'; // Предполагается, что стили будут в этом файле
import Sidebar from './sidebar';

const Workspace = ({ children }) => {

  const [activeItem, setActiveItem] = useState('Заказы');

  return (
      <div className="workspace">
        {children}
        <Sidebar setActiveItem={setActiveItem} />
      </div>
  )};

export default Workspace;
