import React, { useState } from 'react';
import './Vehicles.css';

const Vehicles = () => {
  const [vehicles, setVehicles] = useState([
    { name: 'Газель', number: '389 ВК 01', certificate: 'path-to-certificate-1' },
    { name: 'Форд', number: '786 ВК 01', certificate: 'path-to-certificate-2' },
    { name: 'Мерседес', number: '865 ВК 01', certificate: 'path-to-certificate-3' },
    // ... другие машины
  ]);

  // Функции для обработки добавления и удаления машин

  return (
    <div className="vehicles-container">
      <div className="vehicles-header">
        <button className="vehicles-button">Добавить</button>
        <button className="vehicles-button">Удалить</button>
      </div>
      <table className="vehicles-table">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Номер</th>
            <th>Сертификат</th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map((vehicle, index) => (
            <tr key={index}>
              <td>{vehicle.name}</td>
              <td>{vehicle.number}</td>
              <td>
                <a href={vehicle.certificate} target="_blank" rel="noopener noreferrer">
                  <img src="path-to-icon" alt="Сертификат" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Vehicles;
