import React from 'react';
import { Link } from 'react-router-dom';
import './style/FavouritesProductItem.css'; // Стили для элемента товара

function FavouritesProductItem() {
  return (
    <Link to="/CardProduct" className="product-item-link">
      <div className="product-item">
        <div className="product-image"></div>
        <h3>Название товара</h3>
        <p className="price">Цена</p>
        <button>В корзину</button>
      </div>
    </Link>
  );
}

export default FavouritesProductItem;
