// src/components/SpecialOffers.js

import React from 'react';
import './style/SpecialOffers.css'; // Стили для специальных предложений

function SpecialOffers() {
  return (
    <div className="special-offers-container">
      <div className="special-offer-card loyalty-card">
        <h3>Оформите карту «Лояльность»</h3>
        <p>И получайте бонусы при покупке в магазинах и на сайте</p>
      </div>
      <div className="special-offer-card discount-card">
        <h3>Покупайте акционные товары</h3>
        <p>И получайте ваше больше бонусов</p>
      </div>
    </div>
  );
}

export default SpecialOffers;
