// src/components/Map.js

import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './style/Map.css';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

function Map() {
  return (
    <div className="map-container">
      <iframe
        width="100%"
        height="400"
        frameBorder="0" 
        scrolling="no" 
        marginHeight="0" 
        marginWidth="0"
        src="https://maps.google.com/maps?q=Астана&t=&z=13&ie=UTF8&iwloc=&output=embed"
      >
      </iframe>
    </div>
  )
}

export default React.memo(Map);

// <LoadScript
// googleMapsApiKey="ВАШ_API_КЛЮЧ"
// >
// <GoogleMap
//   mapContainerStyle={containerStyle}
//   center={center}
//   zoom={10}
// >
//   {/* Дочерние элементы карты, такие как маркеры */}
// </GoogleMap>
// </LoadScript>