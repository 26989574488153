import React from 'react';
import { Link } from 'react-router-dom';
import './style/ProductItem.css'; // Стили для элемента товара

function ProductItem() {
  return (
    <div className="product-item">
      <Link to="/CardProduct" className="product-item-link">
        <div className="product-image"></div>
        <h3>Название товара</h3>
        <p className="price">Цена</p>
      </Link>
      <button onClick={() => console.log('Добавлено в корзину')}>В корзину</button>
    </div>
  );
}

export default ProductItem;
