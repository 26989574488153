import React, { useState } from 'react';
import './AddClientModal.css'; // Стили модального окна

const AddClientModal = ({ onClose }) => {
  const [clientData, setClientData] = useState({
    name: '',
    bin: '',
    color: '', // Если нужен цвет, его тоже можно будет выбрать
    contracts: [],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(clientData);
    // Здесь код для добавления клиента
  };

  return (
    <div className="add-client-modal">
      <form onSubmit={handleSubmit}>
        <h2>Добавление клиента</h2>
        <label>
          Наименование:
          <input
            type="text"
            name="name"
            value={clientData.name}
            onChange={handleInputChange}
          />
        </label>
        <label>
          БИН:
          <input
            type="text"
            name="bin"
            value={clientData.bin}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Адрес:
          <input
            type="text"
            name="bin"
            value={clientData.address}
            onChange={handleInputChange}
          />
        </label>
        <label>
          договор:
          <input
            type="text"
            name="bin"
            value={clientData.contracts}
            onChange={handleInputChange}
          />
        </label>
        <label>
          телефон:
          <input
            type="text"
            name="bin"
            value={clientData.telephone}
            onChange={handleInputChange}
          />
        </label>
        <label>
        E-Mail:
          <input
            type="text"
            name="bin"
            value={clientData.bin}
            onChange={handleInputChange}
          />
        </label>
        <label>
        Точки доставки:
          <input
            type="text"
            name="bin"
            value={clientData.bin}
            onChange={handleInputChange}
          />
        </label>
        <label>
        Полное Наименование:
          <input
            type="text"
            name="bin"
            value={clientData.bin}
            onChange={handleInputChange}
          />
        </label>
        {/* Раздел с договорами */}
        <div className="contracts-section">
          <h3>Договора</h3>
          {/* Компоненты или элементы для добавления, удаления и установки договора как основного */}
          {/* ... */}
        </div>
        <div className="modal-actions">
          <button type="button" onClick={onClose}>Отмена</button>
          <button type="submit">Добавить</button>
        </div>
      </form>
    </div>
  );
};

export default AddClientModal;
