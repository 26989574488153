import React from 'react';
import PropTypes from 'prop-types';
import './style/Pagination.css';

const Pagination = ({ itemsCount, itemsPerPage, currentPage, onPageChange }) => {
  const pageCount = Math.ceil(itemsCount / itemsPerPage);
  if (pageCount === 1) return null; // Нет необходимости в пагинации, если все помещается на одной странице

  const pages = Array.from({ length: pageCount }, (_, index) => index + 1);

  return (
    <nav>
      <ul className="pagination">
        {pages.map(page => (
          <li key={page} className={page === currentPage ? 'page-item active' : 'page-item'}>
            <button className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
