import React, { useState } from 'react';
import './Chat.css';

const Chat = () => {
  const [users, setUsers] = useState(['ТОО АКС', 'ЖК форс', 'ИП Нил']); // Список пользователей
  const [search, setSearch] = useState(''); // Поисковой запрос
  const [selectedUser, setSelectedUser] = useState(null); // Выбранный пользователь для чата
  const [messages, setMessages] = useState({}); // Сообщения по пользователям
  const [newMessage, setNewMessage] = useState('');

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    if (!messages[user]) {
      setMessages({ ...messages, [user]: [] });
    }
  };

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const userMessages = messages[selectedUser] || [];
      const updatedMessages = {
        ...messages,
        [selectedUser]: [...userMessages, newMessage],
      };
      setMessages(updatedMessages);
      setNewMessage('');
    }
  };

  // Обновляем состояние поискового запроса
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  // Фильтруем список пользователей в соответствии с поисковым запросом
  const filteredUsers = users.filter((user) =>
    user.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="chat-container">
      <div className="search">
        <input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Поиск..."
        />
      </div>
      <div className="users-list">
        {filteredUsers.map((user) => (
          <div
            key={user}
            onClick={() => handleUserSelect(user)}
            className={`user ${selectedUser === user ? 'active' : ''}`}
          >
            {user}
          </div>
        ))}
      </div>
      <div className="chat-messages">
        {selectedUser && messages[selectedUser]?.map((message, index) => (
          <div key={index} className="message">
            {message}
          </div>
        ))}
      </div>
      {selectedUser && (
        <div className="chat-input">
          <input
            type="text"
            value={newMessage}
            onChange={handleNewMessageChange}
            placeholder="Напишите сообщение..."
          />
          <button onClick={handleSendMessage}>Отправить</button>
        </div>
      )}
    </div>
  );
};

export default Chat;
