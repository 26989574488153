import React, { useState } from 'react';
import './DeliveryAreasComponent.css';
const DeliveryAreasComponent = () => {
  const [deliveryAreas, setDeliveryAreas] = useState([
    { name: 'г. Астана', days: 'пн, чт, сб' },
    { name: 'п. Косшы', days: 'вт, пт' },
    { name: 'с. Картофель', days: 'чт' },
    { name: 'г. Костанай', days: 'пн, пт' }
  ]);
  const [selectedArea, setSelectedArea] = useState(null);

  const handleAdd = () => {
    // Логика добавления нового района доставки
  };

  const handleDelete = () => {
    // Логика удаления выбранного района доставки
  };

  return (
    <div>
      <h2>Районы доставки</h2>
      <button onClick={handleAdd}>Добавить</button>
      <button onClick={handleDelete} disabled={selectedArea === null}>
        Удалить
      </button>
      <table>
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Дни</th>
          </tr>
        </thead>
        <tbody>
          {deliveryAreas.map((area, index) => (
            <tr
              key={index}
              onClick={() => setSelectedArea(index)}
              style={{
                backgroundColor: selectedArea === index ? '#e0e0e0' : 'transparent'
              }}
            >
              <td>{area.name}</td>
              <td>{area.days}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DeliveryAreasComponent;
