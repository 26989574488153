// src/components/TopBanner.js

import React from 'react';
import './style/TopBanner.css'; // Стили для баннера

function TopBanner() {
  return (
    <div className="top-banner">
      <h2>Доставка бесплатно от 10000 Тенге</h2>
    </div>
  );
}

export default TopBanner;
