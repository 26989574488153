import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import './ClientDetails.css'; // Предполагается, что стили будут в этом файле

const ClientDetails = () => {

  const [clients, setCLients] = useState([]);
  const [contracts, setContracts] = useState([]);
  const { id } = useParams();

  // Вывод клиента
  useEffect(() => {
    axios
      .get("http://213.157.41.2:7388/api/v1/clients/" + id, {
        headers: {
          "x-api-key": "12345xxa",
        },
      })
      .then((res) => {
        setCLients(res.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных о заказах:", error);
      });
  }, []);

  // Вывод договоров
  useEffect(() => {
    axios
      .get("http://213.157.41.2:7388/api/v1/contracts/", {
        headers: {
          "x-api-key": "12345xxa",
        },
      })
      .then((res) => {
        setContracts(res.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных о заказах:", error);
      });
  }, []);

  return (
    <div>
      {clients.map((item, index) => (
      <div className="client-details">
        <div className="header">
          <h1>Клиент: {item.name}</h1>
          <div className="id">
            <span>БИН:</span>
            <span>{item.bin}</span>
          </div>
          <div className="color"></div>
        </div><div className="full-name">
            <span>Полное наименование: {item.name}</span>
            <p>Описание полного наименования клиента...</p>
          </div>
          </div>
      ))}
      
      <div className="contracts">
            <h2>Договора</h2>
            <div className="contract-actions">
              <button>Добавить</button>
              <button>Удалить</button>
              <button>Установить основным</button>
            </div>
            <div className="contract-list">
            <table className="order-items">
          <thead>
            <tr>
              <th>Наименование</th>
              <th>Кол-во</th>
              <th>Ед. изм</th>
              <th>Цена</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
          {contracts.filter(item => item.id_client === +id).map((item, index) => (
          <tr key={item.id}>
            <td>{item.contract_number}</td>
            <td>{item.contract_date}</td>
            <td>{item.unit}</td>
            <td>{item.price}</td>
            <td>{item.remaining_amount}</td>
            </tr>
           ))} 
          </tbody>
        </table>           
              
            </div>
          </div>
          
    </div>
  );
};

export default ClientDetails;
