import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style/Cart.css'; 

const Cart = () => (
  <div className="cart-cart-container">
    <div className="cart-breadcrumbs">
      <a href="/">Главная</a> &gt; <span>Корзина</span>
    </div>
      <h2 className="cart-cart-title">Корзина</h2>
    <div className="cart-cart-upper-actions">
      <input type="checkbox" id="select-all" className="cart-select-all-checkbox" />
      <label htmlFor="select-all" className="cart-select-all-label">Выделить все</label>
      <button className="cart-delete-selected">Удалить выбранное</button>
    </div>
    <div className="cart-cart-items-container">
      {[1, 2, 3].map((item, index) => (
      <div className="cart-cart-item" key={index}>
        <input type="checkbox" className="cart-cart-item-select" />
        <div className="cart-cart-item-image"></div>
        <div className="cart-cart-item-info">
          <span className="cart-cart-item-title">Название товара {item}</span>
          <span className="cart-cart-item-price">{(item + 1) * 1000},00 Т</span>
        </div>
        <div className="cart-cart-item-quantity-container">
          <button className="cart-quantity-button">-</button>
          <span className="cart-quantity-number">2</span>
          <button className="cart-quantity-button">+</button>
          <span className="cart-item-total-price">{(item + 1) * 1000 * 2},00 Т</span>
        </div>
      </div>
      ))}
    </div>
    <div className="cart-cart-summary">
      <div className="cart-summary-item">Товаров<span>3</span></div>
      <div className="cart-summary-item">Скидка<span>-100,00 Т</span></div>
      <div className="cart-summary-total">Итог<span>200,00 Т</span></div>
      <Link to="/DeliveryForm" className="cart-DeliveryForm">
        <button className="cart-checkout-button">Оформить заказ</button>
      </Link>
        <div className="cart-minimum-amount">Минимальная сумма заказа 1000Т</div>
    </div>
  </div>
);

export default Cart;
