import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Orders.css'; // Убедитесь, что создали соответствующий CSS файл
import axios from "axios";

const Orders = () => {
  const navigate = useNavigate();

  // Предполагаем, что orders - это массив объектов, каждый из которых представляет заказ
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    axios.get("http://213.157.41.2:7388/api/v1/orders", {
      headers: {
        'x-api-key':'12345xxa'
      }
    })
    .then((res) => {
      setOrders(res.data);
    })
    .catch((error) => {
      console.error("Ошибка при получении данных о заказах:", error);
    });
  }, []);

  // Функция для перехода к странице деталей заказа
  const handleRowClick = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  // Функция для рендеринга строки таблицы
  const renderRow = (order) => (
    <tr key={order.id} onClick={() => handleRowClick(order.id)}>
      <td>{order.created_at}</td>
      <td>{order.id_client}</td>
      <td>{order.contract}</td>
      <td>{order.delivery_address}</td>
      <td>{order.delivery_date}</td>
      <td>{renderStatus(order.id_status)}</td>
      <td>{order.payment_status}</td>
      <td>{order.total_amount}</td>
    </tr>
  );

  // Функция для рендеринга статуса заказа
  const renderStatus = (status) => {
    const statusClasses = {
      'в работе': 'status-in-progress',
      'доставляется': 'status-delivering',
      'в пути': 'status-en-route',
      'получен': 'status-received',
    };

    return <span className={`status ${statusClasses[status]}`}>{status}</span>;
  };

  return (
    <div className="orders-container">
      <div className="filters">
        <button className="filter-button">В работе</button>
        <button className="filter-button">доставляется</button>
        <button className="filter-button">в пути</button>
        <button className="filter-button">получен</button>
        {/* Дополнительные кнопки фильтра */}
      </div>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Дата</th>
            <th>Клиент</th>
            <th>Договор</th>
            <th>Адрес</th>
            <th>Дата доставки</th>
            <th>Статус заказа</th>
            <th>Оплата</th>
            <th>Сумма</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(renderRow)}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
