import React from 'react';
import { useParams } from 'react-router-dom';

const ArticlePage = () => {
  const { articleId } = useParams();
  // Здесь можно добавить логику для получения данных статьи по articleId

  return (
    <div>
      <h1>Чтобы насладится вкусом мраморной говядины, необходимо соблюдать следующие правила приготовления: {articleId}</h1>
      <p>1. Всегда правильно размораживайте мясо. Чтобы при размораживании сохранить качество мяса. Очень важно размораживать мясо в холодильнике при температуре +2 +6 С, до полной оттайки, примерно двое суток. (Ни в коем случае в горячей воде или микроволновой печи!!!). Потеря веса при разморозке составляют от 3% до 7% в зависимости от отруба.

2. Мясо всегда надо начинать жарить на очень высокой температуре. Температура на поверхности для закрытия стейка должна быть не менее 270С. Профессионально это называется закрыть мясо. Для того чтобы все поры которые есть в мясе, закрылись, стянулись, при этом содержащиеся в мясе сахара карамелизируется и образуют ту корочку поджаристую которую все любят и сок останется внутри куска. Поэтому первоначально важно жарить мясо при высокой температуре, не важно, что это гриль, хоспер, мангал и т.д. При жарке мяса оно сжимается и внутри образуется очень высокое давление. Давление жидкости которая циркулирует по мышечным волокнам доходит примерно до 1-1,5 атмосфер. При этом стейк обязательно надо переворачивать, чтобы влага внутри него равномерно распределялась.{articleId}.</p>
    </div>
  );
};

export default ArticlePage;
