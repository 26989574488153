import React from 'react';
import { Card, CardContent, Typography, CardHeader, CardMedia, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CategoryIcon from '@mui/icons-material/Category';
import StraightenIcon from '@mui/icons-material/Straighten'; // Используется для представления "единицы измерения"

const ProductProfile = ({ product }) => {
  return (
    <Card sx={{ maxWidth: "100%", height: "100%", margin: 'auto' }}>
      <CardHeader
        title={product?.name || 'Название товара'}
        subheader={`ID: ${product?.id || 'Неизвестен'}`}
      />
      <CardMedia
        component="img"
        height="200"
        image={product?.imageUrl || '/placeholder-product.png'}
        alt={product?.name || 'Изображение товара'}
      />
      <CardContent>
        <List>
          <ListItem>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Категория" secondary={product?.category || 'Не указана'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Описание" secondary={product?.description || 'Нет описания'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Цена" secondary={product?.price ? `$${product.price}` : 'Не указана'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StraightenIcon /> {/* Иконка, представляющая "единицу измерения" */}
            </ListItemIcon>
            <ListItemText primary="Единица измерения" secondary={product?.unit || 'Не указана'} />
          </ListItem>
          {/* Добавьте дополнительные поля здесь */}
        </List>
        {/* Здесь может быть дополнительная информация о товаре */}
      </CardContent>
    </Card>
  );
};

export default ProductProfile;
