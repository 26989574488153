import React from 'react';
import './Invoice.css';

const Invoice = () => {
  // Предположим, что данные будут загружены или переданы в компонент,
  // здесь они захардкожены для примера
  const invoiceData = {
    organization: 'MB4',
    documentNumber: '4447',
    documentDate: '03.11.2023',
    supplier: 'MB4',
    buyer: 'Списи-сак Кунушка села бибряй Алтынсарина при отделе образования по Ленинградскому',
    note: 'Договор № 33 от 15.03.2023 (ровал 520*2 744 печень 30 *1 680 Орав 220*2 744)',
    warehouse: 'Склад готовой продукции',
    items: [
      { code: '00065', product: 'ОРАЦИ говядина', unit: 'кг', quantity: 30000, price: 2744, sum: 82320 },
      { code: '00247', product: 'Говядина', unit: 'кг', quantity: 106500, price: 2744, sum: 292236 },
      // ... другие товары
    ],
    totalSum: 374556,
    vatSum: 40131
  };

  return (
    <div className="invoice-container">
      <h2>Расходная накладная</h2>
      <div className="invoice-header">
        <div className="invoice-info">
          <p>Организация: {invoiceData.organization}</p>
          <p>Поставщик: {invoiceData.supplier}</p>
          <p>Покупатель: {invoiceData.buyer}</p>
          <p>Склад: {invoiceData.warehouse}</p>
          <p>Примечание: {invoiceData.note}</p>
        </div>
        <div className="document-info">
          <p>Номер документа: {invoiceData.documentNumber}</p>
          <p>Дата документа: {invoiceData.documentDate}</p>
        </div>
      </div>
      <table className="invoice-table">
        <thead>
          <tr>
            <th>№</th>
            <th>Код</th>
            <th>Товар</th>
            <th>Ед. изм.</th>
            <th>Количество</th>
            <th>Цена, тг.</th>
            <th>Сумма, тг.</th>
          </tr>
        </thead>
        <tbody>
          {invoiceData.items.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.code}</td>
              <td>{item.product}</td>
              <td>{item.unit}</td>
              <td>{item.quantity}</td>
              <td>{item.price}</td>
              <td>{item.sum}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="invoice-summary">
        <p>Итого: {invoiceData.totalSum}</p>
        <p>Сумма НДС, тг.: {invoiceData.vatSum}</p>
        <p>Всего по накладной, включая НДС тг.: {invoiceData.totalSum}</p>
      </div>
      <div className="invoice-signatures">
        <p>Отпустил: __________________</p>
        <p>Получил: __________________</p>
      </div>
    </div>
  );
};

export default Invoice;
