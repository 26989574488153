import React from 'react';

const ProfilePage = () => {
  return (
    <div>
      <h2>User Profile</h2>
      <p>Name: John Doe</p>
      <p>Email: john.doe@example.com</p>
    </div>
  );
};

export default ProfilePage;
