import React from 'react';
import Sidebar from './Sidebar';

const HomePage = () => {
  return (
    <div>
      <h1>Добро пожаловать в админ-панель</h1>
      <Sidebar/>
    </div>
  );
};

export default HomePage;
