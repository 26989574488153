import React from 'react';
import './style/ProductBlockAside.css'; // Стили для элемента товара

function ProductBlockAside() {
  return (
    <div className="product-item-A">
      <div className="product-image-A"></div>
      <p className="price-A">Цена</p>
    </div>
  );
}

export default ProductBlockAside;
