import React, { useState } from 'react';
import './ContractsList.css';

import { useNavigate } from 'react-router-dom';

const ContractsList = () => {
  const [contracts, setContracts] = useState([
    {
      id: '63871502-01Z',
      date: '21.01.2024',
      isActive: true, // Добавили свойство isActive
      items: [
        { name: 'Мясо птицы', quantity: 123, unit: 'кг', price: 456, sum: 123323, remainder: 34, remainderSum: 233323 },
        // ... другие товары
      ],
      totalRemainder: 1456346,
    },
    {
      id: '57419923-01Z',
      date: '15.01.2024',
      isActive: false, // Добавили свойство isActive
      items: [
        { name: 'Баранина', quantity: 456, unit: 'кг', price: 3535, sum: 3567123, remainder: 54, remainderSum: 567123 },
        // ... другие товары
      ],
      totalRemainder: 951646,
    },
    // ... другие договора
  ]);
  const [activeFilter, setActiveFilter] = useState('all');
  const navigate = useNavigate();

  // Функция для добавления нового договора
  const handleAddContract = () => { navigate('/ContractsList/AddContract');
    // логика добавления договора
  };

  const filteredContracts = contracts.filter(contract => {
    if (activeFilter === 'active') {
      return contract.isActive;
    } else if (activeFilter === 'inactive') {
      return !contract.isActive;
    }
    return true;
  });

  return (
    <div className="contracts-list">
      <button className="add-contract-btn" onClick={handleAddContract}>Добавить договор</button>
      <div className="filters">
        <button onClick={() => setActiveFilter('all')}>Все</button>
        <button onClick={() => setActiveFilter('active')}>Активные</button>
        <button onClick={() => setActiveFilter('inactive')}>Неактивные</button>
      </div>
      {filteredContracts.map(contract => (
        <div key={contract.id} className="contract">
          <h3>Договор № {contract.id} от {contract.date}</h3>
          <table className="contracts-table">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Кол-во</th>
                <th>Ед. изм.</th>
                <th>Цена</th>
                <th>Сумма</th>
                <th>Остаток Кол-во</th>
                <th>Остаток сумма ТЗ</th>
              </tr>
            </thead>
            <tbody>
              {contract.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.unit}</td>
                  <td>{item.price}</td>
                  <td>{item.sum}</td>
                  <td>{item.remainder}</td>
                  <td>{item.remainderSum}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="total-remainder">
            Суммовой остаток: {contract.totalRemainder}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContractsList;