import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import './Header.css';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';

const Header = () => {
  
    const [showAuth, setShowCategories] = useState(false); // Состояние для отображения всплывающего окна с категориями

    // Категории товаров
    const categories = [<Link to="/AdminPanel">Настройки</Link>, <Link to="/Profile">Профиль</Link>, <Link to="/logout">Выйти</Link>, ];


return (
    <div className="header">
        <div className="header-content">
        <div className="logo">
            <Link to="/">Логотип</Link>
        </div>
        <div className="navigation-container">
            <nav className="navigation">
                <Link to="/">Главная</Link>
                <Link to="/shop">Каталог</Link>
                <Link to="/ShippingAndPayment">Доставка и оплата</Link>
                <Link to="/articles">Статьи</Link>
                <Link to="/about">О нас</Link>
                <Link to="/PressAboutUs">Пресса о нас</Link>
                <Link to="/contact">Контакты</Link>
            </nav>
        </div>
        <div className="icons-container">
            <nav>
                <Link to="/crm"><CropSquareIcon/></Link>
                <Link to="/OrdersPage"><CropSquareIcon/></Link>
                <Link to="/Favourites"><FavoriteBorderIcon/></Link>
                <Link to="/cart"><LocalGroceryStoreOutlinedIcon/></Link>
            </nav>
        </div>
        <div>
            <div
                className="auth-dropdown"
                onMouseEnter={() => setShowCategories(true)} // Показываем всплывающее окно при наведении
                onMouseLeave={() => setShowCategories(false)} // Скрываем всплывающее окно при уходе курсора
                >
                    <FaUser />
                {showAuth && (
                <div className="auth-popup">
                    {categories.map((category, index) => (
                    <div key={index} className="auth-item">
                        {category}
                    </div>
                    ))}
                </div>
                )}
            </div>
        </div>
        </div>
    </div>
);
};

export default Header;
