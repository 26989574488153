import React, { useState } from 'react';
import { TextField, Button, Container, Typography, InputLabel, FormControl, FormHelperText, Input} from '@mui/material';

const CreateProductPage = () => {
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Отправка данных формы, например, через API
    console.log(product);
  };

    // Обработчик для изменения файла изображения
    const handleImageChange = (e) => {
      setProduct({ ...product, image: e.target.files[0] });
    };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Добавить товар
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Название товара"
          name="name"
          value={product.name}
          onChange={handleChange}
          margin="normal"
        />
          <TextField
          fullWidth
          label="Категория"
          name="name"
          value={product.name}
          onChange={handleChange}
          margin="normal"
        />
          <TextField
            fullWidth
            label="Единица Измерения"
            name="description"
            value={product.description}
            onChange={handleChange}
            margin="normal"
          />
        <TextField
          fullWidth
          label="Описание"
          name="description"
          value={product.description}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Цена"
          name="price"
          value={product.price}
          onChange={handleChange}
          margin="normal"
        />
          
          <InputLabel htmlFor="image-input">Выберите изображение товара</InputLabel>
          <FormControl fullWidth margin="normal">
          <Input
            id="image-input"
            type="file"
            name="image"
            onChange={handleImageChange}
            inputProps={{ accept: "image/*" }} // Ограничение выбора файлов только изображениями
          />
          <FormHelperText>Изображение необходимо для визуального представления товара</FormHelperText>
        </FormControl>
        {/* Добавьте здесь Select для категории, если необходимо */}
        <Button variant="contained" color="primary" type="submit">
          Добавить
        </Button>
      </form>
    </Container>
  );
};

export default CreateProductPage;
