import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/HomePage.css"
import image1 from '../images/image1.jpg';
import image2 from '../images/image2.png';



const articles = [
  { id: 1, title: 'Рекомендации по приготовлению. Хоспер и гриль ', content: 'Чтобы насладится вкусом мраморной говядины, необходимо соблюдать следующие правила приготовления: 1. Всегда правильно размораживайте мясо. Чтобы при размораживании сохранить качество мяса. Очень важно размораживать мясо в холодильнике при температуре +2 +6 С, до полной оттайки, примерно двое суток. (Ни в коем случае в горячей воде или микроволновой печи!!!). Потеря веса при разморозке составляют от 3% до 7% в зависимости от отруба.' },
  { id: 2, title: 'Рекомендации по приготовлению. Cу-вид', content: 'Sous-vide или сувид– методика приготовления пищи, которая в русскоязычной гастрономии пока воспринимается как нечто совершенно новое. Тем не менее, в европейской кухне этот способ набирает все большую популярность, особенно в так называемой молекулярной кухне. Однако в последнее время на рынке появилось множество бытовых приборов, которые сделали возможным использовать этот метод готовки и дома.' },
  // Добавьте больше статей здесь
];

const HomePage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="home-container">
      <Slider {...settings}>
        <div>
        <img src={image1} alt="Slide 1" />
        </div>
        <div>
        <img src={image2} alt="Slide 2" />
        </div>
        {/* Добавьте больше слайдов здесь */}
      </Slider>
      <div className="article-container">
        {articles.map((article) => (
          <div key={article.id} className="article">
            <h2>{article.title}</h2>
            <p>{article.content}</p>
            <a href={`/articles/${article.id}`}>Читать дальше</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
